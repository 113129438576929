
import { defineComponent } from 'vue';
// import HelloWorld from './components/HelloWorld.vue';
import Rank from './components/Rank.vue';
import SubmitPortal from './components/SubmitPortal.vue';

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld,
    SubmitPortal,
    // Rank
  }
});
