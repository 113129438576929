<template>
  <div class='submit-portal'>
    <div :class='statusClass'>{{ statusText }}</div>
    <div class='displaytext'>{{ displayTimeText }}</div>
    <div><button @click='submit()'>{{ submitBtnText }}</button></div>
    <div><button @click='timerMode()'>5 sec</button></div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

enum statuses {
  NOT_SUBMITTED,
  EARLY,
  LATE,
}

enum modes {
  REGULAR = 'Regular Mode',
  TIMER = 'Timer Mode'
}

interface IData {
  status: number;
  statusText: string;
  displayTimeText: string;
  displayTime: Date;
  startTime: Date;
  stopTime: Date;
  timer: number;
  submitBtnText: string;
  mode: string;
}

export default defineComponent({
  name: 'SubmitPortal',
  data(): IData {
    return {
      status: statuses.NOT_SUBMITTED,
      statusText: 'Practice submitting your assignment by 11:59pm',
      displayTimeText: '',
      displayTime: new Date(),
      startTime: new Date(),
      stopTime: new Date(),
      timer: 0,
      submitBtnText: 'Submit',
      mode: modes.REGULAR,
    }
  },
  methods: {
    init(): void {
      clearInterval(this.timer);
      this.status = statuses.NOT_SUBMITTED;
      this.statusText = 'Practice submitting your assignment by 11:59pm';
      this.displayTimeText = '00:00:00.000';
      this.submitBtnText = 'Submit';
    },
    startTimer(dsplyHr0: number, dsplyMin0: number, dsplySec0: number, dsplyMilSec0: number): void {
      this.displayTime = this.sethmsms(this.displayTime, dsplyHr0, dsplyMin0, dsplySec0, dsplyMilSec0);
      this.startTime = new Date();
      this.timer = setInterval(() => {
        this.stopTime = new Date();
        this.displayTime.setTime(this.stopTime.getTime() - this.startTime.getTime() + this.displayTime.getTime());
        this.startTime.setTime(this.stopTime.getTime());
        this.displayTimeText = this.formatTime(this.displayTime);
      });
    },
    submit(): void {
      if (this.submitBtnText === 'Submit') {
        this.submitBtnText = 'Submit Another';
        clearInterval(this.timer);
        let deadline = this.sethmsms(new Date(), 12, 0, 0, 0);
        let diffTime = this.diff(deadline, this.displayTime);
        if (deadline >= this.displayTime) {
          this.status = statuses.EARLY;
          this.statusText = `Congratulations! Your assignment was submitted ${diffTime} early!`;
        } else {
          this.status = statuses.LATE;
          this.statusText = `Failed! Your assignemnt was submitted ${diffTime} late`;
        }
      } else {
        this.init();
        this.startTimer(11, 58, 45, 0);
      }
    },
    timerMode(): void {
      this.init();
      this.startTimer(11, 59, 55, 0);
    },
    /**
     * Helper function. Used in startTimer(), to convert displayTime to readable text
     */
    formatTime(date: Date): string {
      let hrs: number | string = date.getHours();
      let min: number | string = date.getMinutes();
      let sec: number | string = date.getSeconds();
      let milsec: number | string = date.getMilliseconds();
      hrs = hrs < 10 ? '0' + hrs : hrs;
      min = min < 10 ? '0' + min : min;
      sec = sec < 10 ? '0' + sec : sec;
      milsec = milsec < 100
      ? ( milsec < 10
        ? '00' + milsec
        : '0' + milsec
      )
      : milsec;
      return `${hrs}:${min}:${sec}.${milsec}`
    },
    /**
     * Helper function. Used in submit(), to calculate diff time and convert to someting readable
     */
    diff(deadline: Date, displayTime: Date): string {
      let diffTime = deadline.getTime() >= displayTime.getTime()
      ? new Date(deadline.getTime() - displayTime.getTime())
      : new Date(displayTime.getTime() - deadline.getTime());
      let s = '';
      if (diffTime.getUTCHours() !== 0) {
        s += diffTime.getUTCHours() + ' hours ';
      }
      if (diffTime.getMinutes() !== 0) {
        s += diffTime.getMinutes() + ' minutes ';
      }
      if (diffTime.getSeconds() !== 0) {
        s += diffTime.getSeconds();
      }
      let milsec: number | string = diffTime.getMilliseconds();
      milsec = milsec < 100
      ? ( milsec < 10
        ? '00' + milsec
        : '0' + milsec
      )
      : milsec;
      s += '.' + milsec + ' seconds';
      return s;
    },
    /**
     * Helper function. Sets date to specified hour, min, sec, milsec. Stands for set hrs, minutes, seconds, and milliseconds.
     */
    sethmsms(date: Date, hrs: number, min: number, sec: number, milsec: number): Date {
      date.setHours(hrs);
      date.setMinutes(min);
      date.setSeconds(sec);
      date.setMilliseconds(milsec);
      return date;
    },
  },
  mounted(): void {
    this.init();
    this.startTimer(11, 58, 45, 0);
  },
  computed: {
    statusClass(): {
      success: boolean;
      failed: boolean;
      statustext: boolean;
    } {
      return {
        success: this.status === statuses.EARLY,
        failed: this.status === statuses.LATE,
        statustext: true,
      }
    },
  }
  
})
</script>

<style scoped>
.submit-portal {
  position: fixed;
  width: 100%;
  height: 100%;
}
.success {
  background: #CFEFCF;
}
.failed {
  background: #EFCFCF;
}
.statustext {
  font-size: 40px;
}
.displaytext {
  font-size: 100px;
}
</style>>